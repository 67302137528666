import { IAppConfig } from './app.config.type';

export const config: IAppConfig = {
    BUILD_VERSION: '0bb4c89c5e5dd44b45c3f6dd3073ba2e8aeae8e3',
    STAGE: 'production',
    origins: {
        APP_URL: 'https://home.bannerflow.com',
        B2_URL: 'https://app.bannerflow.com',
        BRAND_MANAGER: 'https://api.bannerflow.com/brand-manager',
        STUDIO_URL: 'https://studio.bannerflow.com',
        SAPI_URL: 'https://api.bannerflow.com/studio',
        CAMPAIGN_SERVICE_URL: 'https://api.bannerflow.com/cs',
        PUBLISH_SERVICE_URL: 'https://api.bannerflow.com/ps',
        MIGRATION_SERVICE_URL: 'https://migrationservice.bannerflow.com',
        ANALYTICS_BACKEND_SERVICE_URL: 'https://api.bannerflow.com/ab',
        CAMPAIGN_MANAGER_URL: 'https://cm.bannerflow.com',
        AD_SERVICE_URL: 'https://c.bannerflow.net',
        SOCIAL_CAMPAIGN_SERVICE_URL: 'https://scs.bannerflow.com',
        SOCIAL_CAMPAIGN_MANAGER_URL: 'https://scm.bannerflow.com',
        SOCIAL_ACCOUNT_SERVICE_URL: 'https://sas.bannerflow.com',
        SCHEDULE_MIGRATION_CLIENT_URL: 'https://smc.bannerflow.com',
        BAU_URL: 'https://api.bannerflow.com/bau',
        LIST_SERVICE_URL: 'https://api.bannerflow.com/list-service',
        ACCOUNT_ACCESS_URL: 'https://api.bannerflow.com/account-access',
        ANALYTICS_FRONTEND_URL: 'https://analytics.bannerflow.com',
        FEEDS_SUPREME_COMMANDER: 'https://api.bannerflow.net/feeds-supreme-commander',
        FEEDS_CLIENT: 'https://sandbox-feeds.bannerflow.com',
        CREATIVE_PREVIEW_URL: 'https://api.bannerflow.com/preview',
        COMMENTS_URL: 'https://api.bannerflow.com/comment-service'
    },
    GTM_ID: 'GTM-W5CTW5Z',
    AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING:
        'Endpoint=https://bf-shared-ac.azconfig.io;Id=gdD1-l9-s0:gBi7bAZ/eHTR9I4XFT7c;Secret=B7Er0advds2mjybouWaNOYTPy4lpZNz0jNb7FZZs+Yo=',
    AUTH_CLIENT_ID: '5F6LZYP3VQvQB4dWcWOVoQKbAMUWSNSx',
    AUTH_DOMAIN: 'https://login.bannerflow.com',
    AUTH_AUDIENCE: 'https://bannerflow.com/resources/'
};
